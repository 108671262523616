import React from "react"
import { HashRouter as Router, Route } from "react-router-dom"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { AppStateStore } from "./_shared/context/AppStateStore"
import dayjs from "dayjs"
import "dayjs/locale/it"

import RoutedApp from "./RoutedApp"

import "./app.css"

// ----------------------------------------------------------------------------
//dayjs config

dayjs.locale("it")

// ----------------------------------------------------------------------------

const App = (props) => {
    const initialState = {
        user: null,
        colors: "dark",
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "SIGN_IN_FULFILLED": {
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user,
                }
            }

            case "SIGN_OUT": {
                return {
                    ...state,
                    user: null,
                    token: null,
                }
            }

            default: {
                return {
                    ...state,
                }
            }
        }
    }

    const appTheme = {
        screenSizes: {
            mobile: 850,
        },

        shadows: {
            cardBoxShadow: "2px 4px 8px rgba(25,25,25,.1)",
            iconBoxShadow: "2px 2px 4px rgba(0,0,0,.1)",
            insetCardBoxShadow: "inset 2px 2px 4px 0 rgba(25,25,25,.1)",
        },
        colors: {
            primary: "#1D3557",
            secondary: "#4EA8DE",
            success: "#10D061",
            error: "#EA2C2C",
            divider: "#ced0d4",
            pending: "#FFA826",

            lightGrey: "#F5F6F8",
            grey: "#9EA2B8",
            darkGrey: "#434655",

            white: "#FFFFFF",
            black: "#000000",
        },
    }

    const GlobalStyle = createGlobalStyle`
        a {   
            color: ${(props) => props.theme.colors.primaryColor};
			border-color: ${(props) => props.theme.colors.primaryColor} ;

            :hover {
	            text-decoration: underline;
                color: ${(props) => props.theme.colors.primaryColor};
				border-color: ${(props) => props.theme.colors.primaryColor} ;
            }
        }   

		.ant-pagination-item{

			:hover{

				a{
					color: ${(props) => props.theme.colors.primaryColor};
				}
				
			}

		}

		.ant-pagination-item-active{
				border-color: ${(props) => props.theme.colors.primaryColor} ;	

				a {
					color: ${(props) => props.theme.colors.primaryColor};
				}
		}
    `

    return (
        <AppStateStore
            initialState={initialState}
            reducer={reducer}
            storeKey="sanservolo"
        >
            <ThemeProvider theme={appTheme}>
                <GlobalStyle />
                <Router className={props.className}>
                    <Route path="/" component={RoutedApp} />
                </Router>
            </ThemeProvider>
        </AppStateStore>
    )
}

// ----------------------------------------------------------------------------
// Styled wrapper
// ----------------------------------------------------------------------------

const StyledApp = styled(App)`
    & {
        * {
            color: ${(props) => props?.theme?.colors?.textPrimary};
        }
    }
`

export default StyledApp
