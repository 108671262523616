import { Table, message } from "antd"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { AiOutlineDelete } from "react-icons/ai"
import styled from "styled-components"

import { deleteSession, updateSession } from "../../../../api"
import DetailModal from "../../../_shared/components/DetailModal"
import PageTitle from "../../../_shared/components/PageTitleSection"
import Popconfirm from "../../../_shared/components/Popconfirm"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestTable from "../../../_shared/hooks/usePaginatedRestResource"
import { ReactComponent as ArrowDown } from "../../../_shared/icons/arrow_down.svg"
import { ReactComponent as ArrowRight } from "../../../_shared/icons/la_angle-right.svg"
import { ReactComponent as InfoIcon } from "../../../_shared/icons/la_info-circle.svg"
import { ReactComponent as SearchIcon } from "../../../_shared/icons/la_search.svg"

// ----------------------------------------------------------------------------

function _Sessions(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [filter, setFilter] = useState("")
    const [selectedSession, setSelectedSession] = useState(null)
    const [{ token }] = useStateValue()
    const [sessions, fetchSessions, , pagination, , onFiltersChange] =
        useRestTable("/fishing-sessions", token, {
            filters: { status: "closed" },
        })
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        onFiltersChange({ search: filter, status: "closed" })
    }, [filter])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleModalClose() {
        setSelectedSession(null)
    }

    function selectSession(session) {
        setSelectedSession(session)
    }

    async function handleQuantitySet(id, qty) {
        try {
            const session = sessions?.find((s) => s.id === id)
            const quantiy = parseInt(qty)
            if (isNaN(quantiy)) {
                throw new InvalidQuantityError(
                    "La quantità deve essere un numero."
                )
            }
            if (session?.baskets?.length > 0 && quantiy === 0) {
                throw new InvalidQuantityError(
                    "La qunaità deve essere maggiore di 0kg."
                )
            }
            if (session) {
                const newBody = {
                    ...session,
                    area: session?.area?.id,
                    boat: session?.boat?.id,
                    baskets: session?.baskets?.map((b) => b.id) ?? [],
                    qty: quantiy,
                }
                await updateSession(id, newBody, token)
                handleModalClose()
                fetchSessions()
            } else {
                throw new Error("No session with id " + id)
            }
        } catch (e) {
            if (e instanceof InvalidQuantityError) {
                message.error(e.message)
                console.log(e)
            } else {
                console.log(e)
                message.error("Errore durante l'inserimento della quantità")
            }
        }
    }

    function handleFilterChange(e) {
        setFilter(e.target.value)
    }

    function renderFilter() {
        return (
            <div className="filter">
                <SearchIcon className="icn" />
                <input
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder="Cerca per codice"
                />
            </div>
        )
    }

    function renderActions(record) {
        return (
            <div className="actions">
                <Popconfirm
                    placement="left"
                    className="confirm"
                    title="Sei sicuro di voler eliminare questa sessione?"
                    onConfirm={handleDeleteSession(record.id)}
                >
                    <AiOutlineDelete className="delete-icon" />
                </Popconfirm>
                <InfoIcon onClick={() => selectSession(record)} />
                <ArrowRight onClick={() => selectSession(record)} />
            </div>
        )
    }

    function handleRowSelected(selectedRowKeys, selectedRows) {}

    function getCheckboxProps(record) {
        return { name: record.id }
    }

    function renderPagination() {
        return (
            <div className="pagination-wrapper">
                <div></div>
            </div>
        )
    }

    function handleTableChange(pagination) {
        fetchSessions({ pagination })
    }

    function handleDeleteSession(id) {
        return async () => {
            try {
                await deleteSession(id, token)
                message.success("Sessione eliminate con successo")
                fetchSessions()
            } catch (e) {
                console.log(e)
                message.error("Non è stato possibile eliminare la sessione.")
            }
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const rowSelection = {
        onChange: handleRowSelected,
        getCheckboxProps: getCheckboxProps,
    }

    return (
        <div className={props.className}>
            <PageTitle className="page-title" title="Gestisci sessioni" />
            {renderFilter()}
            <Table
                rowKey={(item) => item.id}
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                className="table"
                pagination={pagination}
                dataSource={sessions}
                columns={getColumns(renderActions, selectSession)}
                onChange={handleTableChange}
            />
            {renderPagination()}
            <DetailModal
                onCancel={handleModalClose}
                record={selectedSession}
                onQuantitySet={handleQuantitySet}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Sessions.propTypes = {
    className: PropTypes.string.isRequired,
}

_Sessions.defaultProps = {}

// ----------------------------------------------------------------------------

const Sessions = styled(_Sessions)`
    & {
        overflow: hidden;
        .filter {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-top: 40px;
            input {
                border: none;
                outline: none;
                margin-left: 48px;
                width: 300px;
                font-size: 16px;
                color: ${({ theme }) => theme.colors.primary};
            }
        }
        .data-code {
            height: 20px;
            width: 70px;
            color: ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.success};
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px 4px;
            font-family: RobotoMono-Medium;
            font-size: 13;
            border-radius: 2px;

            &.open {
                background-color: ${({ theme }) => theme.colors.pending};
            }
        }
        .table {
            margin-top: 30px;
            overflow: auto;
            .anticon-caret-down,
            .anticon-caret-up {
                display: none;
            }
            .ant-pagination {
                /* display: none; */
            }
            .date-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                .icn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .insert-action {
                color: ${({ theme }) => theme.colors.secondary};
                text-decoration: underline;
                cursor: pointer;
                font-size: 14px;
            }
            .data-user {
                flex-direction: row;
                align-items: center;
                display: flex;

                .user-badge {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    padding: 1px 4px;
                    height: 20px;
                    /* width: 100px; */
                    font-size: 13px;
                    font-family: Jost-Medium;
                    border-radius: 2px;
                    background-color: ${({ theme }) => theme.colors.primary};
                    color: ${({ theme }) => theme.colors.white};
                    margin-right: 8px;
                    width: fit-content;
                    white-space: nowrap;
                }
            }
            .data-status {
                flex-direction: row;
                align-items: center;
                display: flex;
            }
            .actions {
                flex-direction: row;
                align-items: center;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                .confirm {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .delete-icon {
                        margin-right: 5px;
                        font-size: 18px;
                        fill: ${({ theme }) => theme.colors.error};
                        padding: 0;
                    }
                }
                svg:last-child {
                    margin-left: 2px;
                }
            }
        }
        .badge {
            border-radius: 9999px;
            width: 10px;
            height: 10px;
            margin-right: 12px;
            &.success {
                background-color: ${({ theme }) => theme.colors.success};
            }
            &.pending {
                background-color: ${({ theme }) => theme.colors.pending};
            }
        }
        @media (max-width: 576px) {
            .filter {
                input {
                    margin-left: 10px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Sessions

const getColumns = (renderActions, openAction) => [
    {
        title: "Codice",
        dataIndex: "code",
        key: "code",
        render: CodeRenderer,
    },
    {
        title: renderDateTitle,
        dataIndex: "date",
        key: "date",
        render: (text) => dayjs(text).format("DD MMMM YYYY"),
        sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
        sortDirections: ["ascend", "descend"],
    },
    {
        title: "Zona",
        key: "zone",
        render: (record) => record?.area?.code,
    },
    {
        title: "Utente",
        dataIndex: "user",
        key: "user",
        render: UserRenderer,
    },
    {
        key: "report",
        title: "Report",
        // eslint-disable-next-line
        render: (record) => (
            <a
                href={record?.reportURL}
                target="_blank"
                className="insert-action"
                rel="noreferrer"
            >
                Apri report
            </a>
        ),
    },
    {
        title: "Quantità",
        dataIndex: "qty",
        key: "quantity",
        // eslint-disable-next-line
        render: (text, record) => (
            <QuantityRenderer record={record} text={text} action={openAction} />
        ),
    },
    {
        title: "Stato",
        key: "status",
        render: StatusRender,
    },

    {
        render: renderActions,
    },
]

function renderDateTitle(pageData) {
    const order = pageData.sortColumns[0]?.order
    const icn =
        order === "ascend" ? (
            <div className="arrow-up icn">
                <ArrowDown />
            </div>
        ) : order === "descend" ? (
            <div className="arrow-down icn">
                <ArrowDown />
            </div>
        ) : null
    return (
        <div className="date-title">
            {icn}
            <span>Data</span>
        </div>
    )
}

function CodeRenderer(text, record) {
    return (
        <div
            className={`data-code ${
                record.status === "open" ? "open" : "closed"
            }`}
        >
            #{text}
        </div>
    )
}

function UserRenderer(text, record) {
    return (
        <div className="data-user">
            <div className="user-badge">{record?.user?.cooperative?.name}</div>
            {record?.user?.name} {record?.user?.surname}
        </div>
    )
}

function QuantityRenderer({ text, record, action }) {
    return isQtySet(text) ? (
        <div>{text}kg</div>
    ) : (
        <div className="insert-action" onClick={() => action(record)}>
            Inserisci
        </div>
    )
}

function StatusRender(record) {
    return !isQtySet(record?.qty) ? (
        <div className="data-status">
            <div className="badge pending" />
            Da approvare
        </div>
    ) : (
        <div className="data-status">
            <div className="badge success" />
            Approvato
        </div>
    )
}

export function isQtySet(qty) {
    if (qty === 0) {
        return true
    }
    if (typeof qty === "undefined") {
        return false
    }
    if (qty === null) {
        return false
    } else {
        return true
    }
}

class InvalidQuantityError extends Error {
    constructor(message) {
        super(message)
    }
}
