import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function PageTitleSection(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { title } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <section className={props.className}>
            <h1>{title}</h1>
        </section>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

PageTitleSection.propTypes = {
    className: PropTypes.string.isRequired,
    title: PropTypes.string,
}

PageTitleSection.defaultProps = {
    title: "Titolo pagina",
}

// ----------------------------------------------------------------------------

const StyledPageTitleSection = styled(PageTitleSection)`
    & {
        > h1 {
            font-size: 30px;
            font-weight: bold;
            color: ${(props) => props.theme.colors.textColor};
            letter-spacing: 0;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledPageTitleSection
