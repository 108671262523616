import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { isQtySet } from "../../../RoutedApp/LoggedApp/Sessions"
import { ReactComponent as CloseIcon } from "../../../_shared/icons/close.svg"
import AppButton from "../AppButton"
import AppInput from "../FormItems/AppInput"

// ----------------------------------------------------------------------------

function _DetailModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { record, onQuantitySet, onCancel } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [quantity, setQuantity] = useState(record?.quantity)

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (record?.qty) {
            setQuantity(record?.qty)
        } else {
            setQuantity(record?.baskets?.length * 25 ?? 0)
        }
    }, [record])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleConfirm() {
        onQuantitySet && onQuantitySet(record?.id, quantity)
    }

    function renderField(label, value) {
        return (
            <div className="field">
                <div className={"label"}>{label}</div>
                <div className={"value"}>{value}</div>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    return (
        <div className={props.className}>
            {record && <div className="mask" onClick={onCancel}></div>}
            <div className="content">
                <div className="top-bar">
                    <div className="dateNcode">
                        <div
                            className={`data-code ${
                                record?.status === "open" ? "open" : "closed"
                            }`}
                        >
                            #{record?.code}
                        </div>
                        <span>
                            {dayjs(record?.date).format("DD MMMM YYYY")}
                        </span>
                    </div>
                    <CloseIcon className="close-icon" onClick={onCancel} />
                </div>
                <div className="divider" />
                <div className="row">
                    {renderField("Utente", record?.user?.name)}
                    {renderField(
                        "Cooperativa",
                        record?.user?.cooperative?.name
                    )}
                    {renderField("Imbarcazione", record?.boat?.name)}
                </div>
                <div className="divider" />
                <div className="row">
                    {renderField("Area", record?.area?.code)}
                    {renderField(
                        "Orario",
                        `${record?.startedAt} ${
                            record?.endedAt ? `- ${record.endedAt}` : ""
                        }`
                    )}
                    {renderField("N. di ceste", record?.baskets?.length)}
                </div>
                <div className="divider" />
                <AppInput
                    label="Qunatità di pescato"
                    placeholder="Inserisci la quantità"
                    unit="Kg"
                    value={quantity ?? 0}
                    onChange={setQuantity}
                />
                <div className="divider" />
                <div className="bottom-row">
                    <div className="status-field">
                        <div className="label">Stato</div>
                        <div className="value">
                            <div
                                className={`badge ${
                                    !isQtySet(record?.qty)
                                        ? "pending"
                                        : "success"
                                }`}
                            ></div>
                            {!isQtySet(record?.qty)
                                ? "Da approvare"
                                : "Approvato"}
                        </div>
                    </div>
                    <AppButton
                        onClick={handleConfirm}
                        uxType="primary"
                        htmlType="button"
                    >
                        CONFERMA
                    </AppButton>
                </div>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_DetailModal.propTypes = {
    className: PropTypes.string.isRequired,
    record: PropTypes.object,
    onQuantitySet: PropTypes.func,
}

_DetailModal.defaultProps = {}

// ----------------------------------------------------------------------------

const DetailModal = styled(_DetailModal)`
    & {
        .mask {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: ${({ record }) => (record ? 0.5 : 0)};
            background-color: ${({ theme }) => theme.colors.black};
            transition: all 0.3s ease;
            z-index: 100;
        }
        .content {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            z-index: 101;
            transition: all 0.2s ease;
            height: ${({ record }) => (record ? "400px" : "0vh")};
            width: ${({ record }) => (record ? "500px" : "0vh")};
            background-color: ${({ theme }) => theme.colors.white};
            overflow: hidden;
            padding: ${({ record }) => (record ? "20px" : "0px")};
            border-radius: 8px;

            .divider {
                margin: 16px 0px;
                width: 100%;
                height: 1px;
                background-color: ${({ theme }) => theme.colors.lightGrey};
            }
            .top-bar {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .dateNcode {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 22px;
                    .data-code {
                        margin-right: 10px;
                    }
                    span {
                        font-size: 18px;
                    }
                }
                svg {
                    cursor: pointer;
                }
            }
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                .field {
                    .label {
                        font-size: 14;
                        color: ${({ theme }) => theme.colors.grey};
                    }
                    .value {
                        font-size: 15;
                        color: ${({ theme }) => theme.colors.primary};
                        max-width: 100px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
            .bottom-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .status-field {
                    .label {
                        font-size: 14;
                        color: ${({ theme }) => theme.colors.grey};
                    }
                    .value {
                        font-size: 15;
                        color: ${({ theme }) => theme.colors.primary};
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .badge {
                            &.success {
                            }
                            &.pending {
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 576px) {
            .content {
                width: ${({ record }) => (record ? "95vw" : "0vh")};
                .row {
                    grid-template-columns: repeat(3, minmax(100px, 1fr));
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default DetailModal
