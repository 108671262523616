import React, { useRef, useEffect } from "react"

//------------------------------------------------------------------------------

export default function usePrevious(value) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const ref = useRef()

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes

    // -------------------------------------
    // local functions
    // -------------------------------------

    // -------------------------------------
    // returned values
    // -------------------------------------

    return ref.current
}
