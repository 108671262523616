import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Input from "../../../../../_shared/components/FormItems/AppInput"
import StyledAppButton from "../../../../../_shared/components/AppButton"

// ----------------------------------------------------------------------------

function UserDataFormTab(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { values, onValueChange, isNewUser } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (event) {
            const value = event?.target?.value

            onValueChange && onValueChange(key, value)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <div className="grid">
                <Input
                    className="input"
                    label="Codice"
                    value={values?.code}
                    onChange={handleFormValueChange("code")}
                />

                {isNewUser && (
                    <Input
                        className="input"
                        label="Password"
                        value={values?.password}
                        onChange={handleFormValueChange("password")}
                    />
                )}
            </div>

            <div className="grid">
                <Input
                    className="input"
                    label="Nome"
                    value={values?.name}
                    onChange={handleFormValueChange("name")}
                />
                <Input
                    className="input"
                    label="Cognome"
                    value={values?.surname}
                    onChange={handleFormValueChange("surname")}
                />
            </div>
            <div className="grid">
                <Input
                    className="input"
                    label="Email"
                    value={values?.email}
                    onChange={handleFormValueChange("email")}
                />
                <Input
                    className="input"
                    label="Telefono"
                    value={values?.phone}
                    onChange={handleFormValueChange("phone")}
                />
            </div>

            <div className="grid">
                <Input
                    className="input"
                    label="Città"
                    value={values?.city}
                    onChange={handleFormValueChange("city")}
                />
                <Input
                    className="input"
                    label="Indirizzo"
                    value={values?.address}
                    onChange={handleFormValueChange("address")}
                />
            </div>

            <div className="grid">
                <Input
                    className="input"
                    label="Azienda"
                    value={values?.businessName}
                    onChange={handleFormValueChange("businessName")}
                />

                <Input
                    className="input"
                    label="SDI"
                    value={values?.sdi}
                    onChange={handleFormValueChange("sdi")}
                />
            </div>

            <div className="grid">
                <Input
                    className="input"
                    label="Partita IVA"
                    value={values?.vatNumber}
                    onChange={handleFormValueChange("vatNumber")}
                />

                <Input
                    className="input"
                    label="Codice fiscale"
                    value={values?.fiscalCode}
                    onChange={handleFormValueChange("fiscalCode")}
                />
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

UserDataFormTab.propTypes = {
    className: PropTypes.string.isRequired,
    values: PropTypes.object,
    onValueChange: PropTypes.func,
}

UserDataFormTab.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledUserDataFormTab = styled(UserDataFormTab)`
    & {
        max-width: 600px;
        .delete-btn {
            margin-top: 40px;
        }
        .grid,
        .row {
            margin-top: 40px;
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            align-items: center;
        }

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .input.inner {
            margin-top: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledUserDataFormTab
