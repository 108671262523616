import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function NotFound(props) {
    return <div className={props.className}>404 NOT FOUND!!</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

NotFound.propTypes = {
    className: PropTypes.string.isRequired,
}

NotFound.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledNotFound = styled(NotFound)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default StyledNotFound
