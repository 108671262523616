import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tabs, message } from "antd"

import * as api from "../../../../../api"

import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"
import usePrevious from "../../../../_shared/hooks/usePrevious"

import BottomSheet from "../../../../_shared/components/BottomSheet"

import UserDataFormTab from "./UserDataFormTab"
import UserDocumentsFormTab from "./UserDocumentsFormTab"

// ----------------------------------------------------------------------------

const { TabPane } = Tabs

// User documents legend
// BA=DDT (Bolle Accompagnamento)
// FR Fatture da DDT
// FT Fatture immediate o accompagnatorie
// OC Ordini clienti

// ----------------------------------------------------------------------------

function UserBottomSheetForm(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { open, onConfirm, onCancel, defaultValues, onDelete } = props

    const isConfirmedUser = defaultValues?.code ? true : false

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const formDefaultValues = defaultValues || {}

    const [formValues, setFormValue, setFormValues, clearFormValues] =
        useForm(formDefaultValues)
    const previousDefaultValues = usePrevious()

    const [userData, setUserData] = useState()
    const [isDocumentsLoading, setIsDocumentsLoading] = useState()

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (
            !previousDefaultValues ||
            previousDefaultValues?.id !== defaultValues?.id
        ) {
            setFormValues(defaultValues)

            const isConfirmed = defaultValues?.code ? true : false

            if (defaultValues?.id && isConfirmed) {
                fetchUserDocuments(defaultValues?.id)
            }
        }

        //eslint-disable-next-line
    }, [defaultValues])

    useEffect(() => {
        !open && clearFormValues()

        //eslint-disable-next-line
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function fetchUserDocuments(userId) {
        try {
            setIsDocumentsLoading(true)
            const userData = await api.getUser(userId, token)

            setUserData(userData)
        } catch (error) {
            console.error(error)
        } finally {
            setIsDocumentsLoading(false)
        }
    }

    function handleSubmit(event) {
        event.preventDefault()

        onConfirm && onConfirm(formValues)
    }

    function handleConfirm() {
        onConfirm && onConfirm(formValues)
    }

    function handleFormValueChange(key, value) {
        setFormValue(key, value)
    }

    function filterDocumentsByType(docType) {
        return userData?.documents?.filter((doc) => doc.type === docType)
    }

    async function handleUserDelete() {
        onDelete && onDelete(defaultValues.id)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    // -------------------------------------

    return (
        <BottomSheet
            open={open}
            className={props.className}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            title="Modifica utente"
            snapPoint={90}
        >
            <form className="form" onSubmit={handleSubmit}>
                <Tabs tabPosition={"top"}>
                    <TabPane tab="Anagrafica" key="data">
                        <UserDataFormTab
                            values={formValues}
                            onValueChange={handleFormValueChange}
                            isNewUser={!isConfirmedUser}
                            onDelete={handleUserDelete}
                        />
                    </TabPane>

                    {isConfirmedUser && (
                        <>
                            <TabPane
                                tab="DDT (Bolle Accompagnamento)"
                                key="ddt"
                            >
                                <UserDocumentsFormTab
                                    documents={filterDocumentsByType("BA")}
                                    loading={isDocumentsLoading}
                                />
                            </TabPane>

                            <TabPane tab="Fatture da DDT" key="ddt-invoices">
                                <UserDocumentsFormTab
                                    documents={filterDocumentsByType("FR")}
                                    loading={isDocumentsLoading}
                                />
                            </TabPane>

                            <TabPane
                                tab="Fatture immediate o accompagnatorie"
                                key="invoices"
                            >
                                <UserDocumentsFormTab
                                    documents={filterDocumentsByType("FT")}
                                    loading={isDocumentsLoading}
                                />
                            </TabPane>

                            <TabPane tab="Ordini clienti" key="orders">
                                <UserDocumentsFormTab
                                    documents={filterDocumentsByType("OC")}
                                    loading={isDocumentsLoading}
                                />
                            </TabPane>
                        </>
                    )}
                </Tabs>
            </form>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

UserBottomSheetForm.propTypes = {
    className: PropTypes.string.isRequired,
}

UserBottomSheetForm.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledUserBottomSheetForm = styled(UserBottomSheetForm)`
    & {
        .ant-tabs-content-holder {
            padding-top: 30px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledUserBottomSheetForm
