import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import closeIcon from "./icons/close-icn.svg"

import Button from "../AppButton"
import FloatingButton from "../FloatingButton"

// ----------------------------------------------------------------------------

function BottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { open, children, onCancel, onConfirm, title, renderExtraButtons } =
        props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    if (open) {
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflow = ""
    }

    return (
        <div className={`${props.className}${open ? " open" : ""}`}>
            <div className="mask" />

            <div className="sheet">
                <div className="header">
                    <div className="title">{(title && title) || ""}</div>

                    <FloatingButton icon={closeIcon} onClick={onCancel} />
                </div>

                {open && <div className="sheet-content">{children}</div>}

                {(onConfirm || onCancel) && (
                    <div className="footer">
                        {renderExtraButtons && (
                            <div className="extra-btns">
                                {renderExtraButtons()}
                            </div>
                        )}

                        {onCancel && (
                            <Button onClick={onCancel} uxType="secondary">
                                Annulla
                            </Button>
                        )}
                        {onConfirm && (
                            <Button className="confirm-btn" onClick={onConfirm}>
                                Salva
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

BottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.func,
    children: PropTypes.any,
    onCancel: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    snapPoint: PropTypes.oneOf([40, 50, 80, 100]),
    renderExtraButtons: PropTypes.func,
}

BottomSheet.defaultProps = {
    open: () => {},
    snapPoint: 40,
    renderExtraButtons: () => {},
}

// ----------------------------------------------------------------------------

const StyledBottomSheet = styled(BottomSheet)`
    & {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        overflow-x: hidden;

        z-index: 998;

        ::-webkit-scrollbar {
            display: none;
        }

        .sheet {
            position: fixed;
            top: ${(props) => {
                return (
                    100 -
                    (props?.snapPoint ?? BottomSheet.defaultProps.snapPoint)
                )
            }}vh;
            left: 0;

            display: flex;
            flex-direction: column;

            box-sizing: border-box;
            padding: 40px 40px 20px 40px;
            border-radius: 20px 20px 0 0;

            width: 100vw;
            height: ${(props) =>
                props?.snapPoint ?? BottomSheet.defaultProps.snapPoint}vh;

            background-color: ${(props) =>
                props.theme.colors.primaryBackgroundColor};
            z-index: 999;

            overflow: auto;

            ::-webkit-scrollbar {
                display: none;
            }

            .header {
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;

                .title {
                    flex: 1;

                    font-size: 18px;
                    font-weight: 600;
                    color: ${(props) => props.theme.colors.textPrimary};
                }

                .close-icn {
                    margin-left: 10px;

                    width: 16px;
                    height: 16px;
                    background-image: url(${closeIcon});
                    background-size: 16px 16px;
                    background-repeat: no-repeat;
                    background-origin: content-box;

                    cursor: pointer;

                    transition: all 0.2s ease;
                }
            }

            .sheet-content {
                flex: 1;
            }

            .footer {
                margin-top: 20px;

                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .extra-btns {
                    margin-right: 10px;
                }

                .confirm-btn {
                    margin-left: 10px;
                }
            }
        }

        .mask {
            position: fixed;
            opacity: 0;
            transition: opacity 0.1s ease 0.3s;
            z-index: 998;
        }
    }

    &.open {
        transform: translateY(0);

        .mask {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scale(1);
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(5px);
        }

        /* MOBILE */

        @media (max-width: ${(props) => props.theme.screenSizes.mobile}px) {
            .drawer {
                min-width: 100vw;
                width: 100vw;
                max-width: 100vw;
                height: 80vh;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledBottomSheet
