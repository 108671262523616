import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { ReactComponent as BG } from "../../../_shared/images/background.svg"

// ----------------------------------------------------------------------------

function _Background(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <div className="bg">
                <img
                    src={process.env.PUBLIC_URL + "background.svg"}
                    className="svg"
                ></img>
            </div>
            <main>{props.children}</main>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Background.propTypes = {
    className: PropTypes.string.isRequired,
}

_Background.defaultProps = {}

// ----------------------------------------------------------------------------

const Background = styled(_Background)`
    & {
        main {
            z-index: 1;
        }
        .bg {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: ${({ theme }) => theme.colors.primary};
            display: flex;
            align-items: center;
            justify-content: center;
            .svg {
                object-fit: cover;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                object-position: center center;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Background
