import { Layout, Menu } from "antd"
import PropTypes from "prop-types"
import React from "react"
import { NavLink as Link, Route, Switch, useLocation } from "react-router-dom"
import styled from "styled-components"

import {
    ApartmentOutlined,
    AppstoreOutlined,
    CopyOutlined,
    OrderedListOutlined,
    PoweroffOutlined,
    UnorderedListOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons"

import { useStateValue } from "../../_shared/context/AppStateStore"
import logo from "../../_shared/images/Logo.png"
import Sessions from "./Sessions"
import Users from "./Users"

// ----------------------------------------------------------------------------

const { Content, Footer, Sider } = Layout

// ----------------------------------------------------------------------------

function LoggedApp(props) {
    const [, dispatch] = useStateValue()
    const { pathname } = useLocation()

    // -------------------------------------

    const renderRoutedContent = () => {
        return (
            <Switch>
                <Route path="/" exact component={Sessions} />
                <Route path="/users" exact component={Users} />
            </Switch>
        )
    }

    function handleSignOut() {
        dispatch({
            type: "SIGN_OUT",
        })
    }

    function isRouteActive(path) {
        if (pathname === path) {
            return "ant-menu-item-selected"
        } else return ""
    }

    return (
        <div className={props.className}>
            <Layout>
                <Sider className="sidebar" breakpoint="sm" collapsedWidth="0">
                    <div className="logo" />

                    <Menu theme="light" mode="inline" />

                    <div
                        className="signout ant-menu-item"
                        onClick={handleSignOut}
                    >
                        <PoweroffOutlined />
                        <span className="menu-text">Esci</span>
                    </div>
                </Sider>
                <Layout>
                    <div className="main-container">
                        <Content>{renderRoutedContent()}</Content>
                        <Footer style={{ textAlign: "center" }}>
                            Vongola verace veneziana ©{" "}
                            {new Date().getFullYear()}
                        </Footer>
                    </div>
                </Layout>
            </Layout>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired, // from router
    location: PropTypes.object.isRequired, // from router
}

LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------
// Styled wrapper
// ----------------------------------------------------------------------------

const StyledLoggedApp = styled(LoggedApp)`
    /* GENERAL APP STYLES */

    * {
        color: ${(props) => props.theme.colors.primary};
    }

    /* ------------------ */
    & {
        min-height: 100vh;
        max-height: 100vh;
        min-width: 100vw;
        overflow-x: hidden;
        background-color: ${(props) => props.theme.colors.primary};

        .ant-layout {
            min-height: 100vh;
            max-height: 100vh;

            background-color: ${(props) => props.theme.colors.white};

            aside {
                background: ${(props) => props.theme.colors.primary};
                /* background-color: transparent; */
                /* border-radius: 0 20px 20px 0; */

                .ant-layout-sider-children {
                    display: flex;
                    flex-direction: column;
                }

                .logo {
                    box-sizing: border-box;
                    padding: 20px;
                    min-height: 150px;

                    background-image: url(${logo});
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-origin: content-box;
                    background-position: center center;
                }

                .anticon.anticon-poweroff {
                    fill: ${(props) => props.theme.colors.white} !important;
                    color: ${(props) => props.theme.colors.white} !important;
                    svg {
                        fill: ${(props) => props.theme.colors.white} !important;
                        color: ${(props) =>
                            props.theme.colors.white} !important;
                    }
                    :hover {
                        color: #ffffff !important;
                    }
                }

                .ant-layout-sider-zero-width-trigger-left {
                    background-color: white;
                    top: 29px;
                    /* left: 5px; */
                }

                .signout {
                    padding: 20px auto;
                    margin-left: 24px;
                    margin-bottom: 20px;
                    span {
                        color: ${(props) => props.theme.colors.white};
                    }
                }

                .divider {
                    margin: auto;
                    width: 90%;
                    height: 1px;
                    background: rgb(31, 40, 58);
                    box-shadow: rgba(255, 255, 255, 0.05) 0px 1px 0px;
                    border-radius: 30px;
                }

                .ant-menu {
                    flex: 1;
                    background-color: transparent;
                    padding-top: 30px;

                    .ant-menu-item {
                        position: relative;
                        padding-left: 22px;
                        padding-right: 0;

                        a {
                            padding-left: 22px;
                            color: ${(props) => props.theme.colors.white};

                            .anticon {
                                * {
                                    color: ${(props) =>
                                        props.theme.colors.white};
                                }
                            }

                            :hover {
                                text-decoration: none;
                            }
                        }

                        .menu-text {
                            color: ${(props) => props.theme.colors.white};
                            font-weight: normal;
                        }
                    }

                    .ant-menu-item:active {
                        background: none;
                    }

                    .ant-menu-item-selected {
                        position: relative;
                        padding-left: 0;
                        margin-left: 22px;

                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        height: 40px;
                        width: calc(100% - 22px);

                        overflow: visible;
                        background-color: ${(props) =>
                            props.theme.colors.secondary};

                        ::before,
                        ::after {
                            box-sizing: border-box;
                            display: none;
                        }

                        ::before {
                            content: "";
                            position: absolute;
                            height: 20px;
                            width: 10px;
                            background: transparent;
                            bottom: -20px;
                            right: 0px;
                            border-top-right-radius: 10px;
                            box-shadow: rgb(255, 255, 255) 1px -10px 0px 0px;
                        }

                        ::after {
                            content: "";
                            position: absolute;
                            height: 20px;
                            width: 10px;
                            background: transparent;
                            top: -20px;
                            right: 0px;
                            border-bottom-right-radius: 10px;
                            box-shadow: rgb(255, 255, 255) 1px 10px 0px 0px;
                        }

                        .current-route {
                            background: ${(props) =>
                                props.theme.colors.secondary};

                            .anticon {
                                * {
                                    color: ${(props) =>
                                        props.theme.colors.white};
                                }
                            }

                            .menu-text {
                                font-weight: 600;
                                color: ${(props) => props.theme.colors.white};
                            }
                        }
                    }
                }

                .sign-out-icon {
                    padding: 20px 0;
                }
            }

            .main-container {
                display: flex;
                flex-direction: column;

                height: 100%;

                overflow-y: auto;
                @media (max-width: 576px) {
                    padding-left: 30px;
                }

                main {
                    flex: 1;
                    padding: 24px;
                    height: auto;
                    min-height: auto;
                }

                footer {
                    padding: 10px;
                    background-color: transparent;
                    color: #7f8fa4;
                    font-size: 10px;
                }
            }
        }
    }
`
export default StyledLoggedApp
