import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Tag } from "antd"

import * as api from "../../../../api/"

import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
import Table from "../../../_shared/components/Table"
import PageTitle from "../../../_shared/components/PageTitleSection"

import UserBottomSheetForm from "./UserBottomSheetForm"
import FloatingButton from "../../../_shared/components/FloatingButton"
import { FiEdit2, FiTrash } from "react-icons/fi"
import Popconfirm from "../../../_shared/components/Popconfirm"

// ----------------------------------------------------------------------------

function Users(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [
        newUsers,
        fetchNewUsers,
        isNewUsersLoading,
        newUsersPagination,
        newUsersCount,
    ] = usePaginatedRestResource("/users", token, {
        filters: {
            status: "new",
        },
    })

    console.log("new users: ", newUsers)
    const [users, fetchUsers, isLoading, pagination, total] =
        usePaginatedRestResource("/users", token, {
            filters: {
                status: "active",
            },
        })
    const [selectedUser, setSelectedUser] = useState(null)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleUserSave(formValues) {
        try {
            const userId = selectedUser?.id

            if (userId) {
                await api.updateUser(userId, formValues, token)

                fetchUsers()

                setSelectedUser(null)

                message.success("Credenziali salvate con successo")
            }
        } catch (error) {
            message.error("Si è verificato un errore.", error)
        }
    }

    async function handleUserDelete(id) {
        try {
            await api.deleteUser(id, token)
            message.success("Utente cancellato")
            setSelectedUser(null)
            fetchUsers()
        } catch {
            message.error("Non siamo riusciti a cancellare l'utente")
        }
    }

    function handleTableChange(pagination) {
        fetchUsers({ pagination })
    }

    function handleNewUsersTableChange(pagination) {
        fetchNewUsers({ pagination })
    }

    function getPaginationObject() {
        return {
            size: "small",
            total: total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            showSizeChanger: false,
        }
    }

    function getNewUsersPaginationObject() {
        return {
            size: "small",
            total: newUsersCount,
            current: newUsersPagination.current,
            pageSize: newUsersPagination.pageSize,
            showSizeChanger: false,
        }
    }

    function handleRowClick(record) {
        return function () {
            setSelectedUser(record)
        }
    }

    function renderRowActions(row) {
        return (
            <div className="row-actions">
                <FloatingButton>
                    <FiEdit2 className="icn" onClick={handleRowClick(row)} />
                </FloatingButton>
                <Popconfirm
                    onConfirm={() => handleUserDelete(row.id)}
                    placement="left"
                    title="Sei sicuro di voler rimuovere questo video?"
                    okText="Sì"
                    cancelText="No"
                >
                    <FloatingButton>
                        <FiTrash className="icn" />
                    </FloatingButton>
                </Popconfirm>
            </div>
        )
    }

    // -------------------------------------

    function handleModalDismiss() {
        setSelectedUser(null)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    // const FormComponent =

    console.log("USER: ", users)

    return (
        <div className={props.className}>
            <PageTitle
                className="page-title"
                title={"Nuove richieste di registrazione"}
            />

            <div className="container">
                <Table
                    dataSource={newUsers}
                    rowKey={(row) => row.id}
                    columns={getColumns()}
                    loading={isNewUsersLoading}
                    pagination={getNewUsersPaginationObject()}
                    onChange={handleNewUsersTableChange}
                    checkStrictly={true}
                    onRow={(record) => {
                        return {
                            onClick: handleRowClick(record),
                        }
                    }}
                />
            </div>

            <PageTitle className="page-title" title={"Utenti attivi"} />

            <div className="container">
                <Table
                    className="table"
                    dataSource={users}
                    rowKey={(row) => row.id}
                    columns={getColumns(renderRowActions)}
                    loading={isLoading}
                    pagination={getPaginationObject()}
                    onChange={handleTableChange}
                    checkStrictly={true}
                    onRow={(record) => {
                        return {
                            //   onClick: handleRowClick(record),
                        }
                    }}
                />
            </div>

            <UserBottomSheetForm
                open={selectedUser ? true : false}
                onConfirm={handleUserSave}
                onCancel={handleModalDismiss}
                defaultValues={selectedUser}
                onDelete={handleUserDelete}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Users.propTypes = {
    className: PropTypes.string.isRequired,
}

Users.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledUsers = styled(Users)`
    & {
        .table {
            tr {
                .row-actions {
                    display: flex;
                    justify-content: space-between;
                    transition: all 0.2s ease-in-out;
                    opacity: 0;
                }

                :hover {
                    .row-actions {
                        opacity: 1;
                    }
                }
            }
        }

        .icn {
            font-size: 20px;
            color: ${(props) => props?.theme?.colors?.textPrimary};
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledUsers

// ----------------------------------------------------------------------------

export function getColumns(renderActions) {
    return [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Cognome",
            dataIndex: "surname",
            key: "surname",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Ragione sociale",
            dataIndex: "businessName",
            key: "businessName",
        },
        {
            title: "Codice",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "",
            dataIndex: "code",
            key: "isNew",
            render: (code) => !code && <Tag color="blue">NUOVO</Tag>,
        },
        {
            key: "actions",
            title: "",
            render: renderActions,
        },
    ]
}
