import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function FloatingButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children, onClick, type, icon } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderBtnContent() {
        return children ?? <img alt="" src={icon} />
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <button className={`${props.className}`} type={type} onClick={onClick}>
            {renderBtnContent()}
        </button>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

FloatingButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    style: PropTypes.string,
    icon: PropTypes.any,
    size: PropTypes.number,
    children: PropTypes.element,
}

FloatingButton.defaultProps = {
    onClick: () => {},
    type: "submit",
    style: "default",
    size: 40,
}

// ----------------------------------------------------------------------------

const StyledFloatingButton = styled(FloatingButton)`
    & {
        position: relative;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        max-width: ${(props) =>
            props.size ?? FloatingButton.defaultProps.size}px;
        width: ${(props) => props.size ?? FloatingButton.defaultProps.size}px;
        height: ${(props) => props.size ?? FloatingButton.defaultProps.size}px;
        max-height: ${(props) =>
            props.size ?? FloatingButton.defaultProps.size}px;

        border: none;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.iconButtonBackground};
        color: ${(props) => props.theme.colors.textPrimary} !important;
        cursor: pointer;

        outline: none;
        transition: all 0.2s ease-in-out;

        z-index: 1;

        > * {
            z-index: 3;
        }

        :hover {
            ::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: ${(props) =>
                    props.theme.colors.hoverTransparency};

                z-index: 2;
            }
        }

        :active {
            transform: scale(0.95);
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledFloatingButton
