import { useState } from "react"

//------------------------------------------------------------------------------

export default function useForm(initialValue) {
    const [formValues, setFormValues] = useState(initialValue || {})

    // -------------------------------------

    function updateFormValue(key, value) {
        const newValues = {
            ...formValues,
            [key]: value,
        }

        setFormValues(newValues)
    }

    function updateFormValues(values) {
        const newValues = {
            ...formValues,
            ...values,
        }

        setFormValues(newValues)
    }

    function clearFormValues() {
        setFormValues(initialValue)
    }

    // -------------------------------------

    return [formValues, updateFormValue, updateFormValues, clearFormValues]
}
