import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import dayjs from "dayjs"

import Table from "../../../../../_shared/components/Table"

// ----------------------------------------------------------------------------

function UserDocumentsFormTab(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { documents, loading } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <Table
                className="doc-table"
                dataSource={documents}
                columns={getColumns()}
                loading={loading}
                rowKey={(row) => row.documentName}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

UserDocumentsFormTab.propTypes = {
    className: PropTypes.string.isRequired,
    documents: PropTypes.array,
    loading: PropTypes.bool,
}

UserDocumentsFormTab.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledUserDocumentsFormTab = styled(UserDocumentsFormTab)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default StyledUserDocumentsFormTab

// ----------------------------------------------------------------------------
// Internals
// ----------------------------------------------------------------------------

function formatDate(date) {
    return date ? dayjs(date).format("DD/MM/YYYY") : ""
}

function formatAmount(amount) {
    return amount?.toFixed(2).replace(".", ",")
}

function getColumns() {
    return [
        {
            title: "Data",
            key: "date",
            dataIndex: "date",
            render: formatDate,
        },

        {
            title: "Nome documento",
            key: "documentName",
            dataIndex: "documentName",
        },
        {
            title: "Imponibile (€)",
            key: "totalTaxable",
            dataIndex: "totalTaxable",
            render: formatAmount,
        },
        {
            title: "IVA (€)",
            key: "vat",
            dataIndex: "vat",
            render: formatAmount,
        },
        {
            title: "Totale (€)",
            key: "finalTotal",
            dataIndex: "finalTotal",
            render: formatAmount,
        },
    ]
}
