import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

//------------------------------------------------------------------------------

function AppInput(props) {
    const {
        className,
        label,
        hiddenText,
        value,
        validationMessage,
        disabled,
        required,
        placeholder,
        unit,
        type,
    } = props

    // -------------------------------------

    const [focused, setFocused] = useState(false)

    function onChange(event) {
        const { onChange } = props

        onChange(event?.target?.value)
    }

    function getStateClass() {
        let cssClass = ""
        cssClass = value ? cssClass + "valid focused" : cssClass
        cssClass = focused ? cssClass + " focused" : cssClass
        return cssClass
    }

    function handleFocusChange() {
        setFocused(!focused)
    }

    // -------------------------------------

    return (
        <div className={`${className} app-input`}>
            {label && <span className="label">{label}</span>}
            <div className={`input-group ${getStateClass()}`}>
                <input
                    className="inner-input"
                    value={value}
                    onChange={onChange}
                    required={required}
                    placeholder={placeholder}
                    disabled={disabled}
                    onFocus={handleFocusChange}
                    onBlur={handleFocusChange}
                    type={type}
                ></input>
                {unit && <div className="unit">{unit}</div>}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppInput.propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string,
    hiddenText: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    validationMessage: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    unit: PropTypes.string,
    type: PropTypes.string,
}

AppInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    validationMessage: null,
    disabled: false,
}

// ----------------------------------------------------------------------------

const StyledAppInput = styled(AppInput)`
    & {
        width: 100%;
        .label {
            display: block;
            font-size: 16px;
            line-height: 135%;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 8px;
        }
        .input-group {
            &.valid,
            &.focused {
                .inner-input {
                    color: ${({ theme }) => theme.colors.primary};
                }
                border: 1px solid ${({ theme }) => theme.colors.secondary};
            }
        }
        .input-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 12px 16px;
            height: 46px;
            background-color: ${({ theme }) => theme.colors.lightGrey};
            border: 1px solid ${({ theme }) => theme.colors.lightGrey};
            border-radius: 8px;

            &:focus {
                border: 1px solid ${({ theme }) => theme.colors.secondary};
                outline: none;
            }
        }
        .inner-input {
            background-color: inherit;
            height: 100%;
            width: 100%;
            border: none;
            outline: none;
            font-size: 16px;
            box-sizing: border-box;

            ::placeholder {
                color: ${({ theme }) => theme.colors.grey};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledAppInput
