import { message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import * as api from "../../../api"
import AppButton from "../../_shared/components/AppButton"
import Background from "../../_shared/components/Background"
import AppInput from "../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../_shared/context/AppStateStore"
import Logo from "../../_shared/images/Logo.png"
import CommandLine from "./CommandLine"

//------------------------------------------------------------------------------

function AuthPage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [, dispatch] = useStateValue()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderAuthCard() {
        return (
            <form className="auth-card" onSubmit={handleAuth}>
                <h2 className="card-title">Benvenuto,</h2>
                <span className="prompt">
                    Effettua il login per accedere alla piattaforma
                </span>
                <AppInput
                    placeholder="email@domino.com"
                    label="Email"
                    className="input"
                    value={email}
                    onChange={setEmail}
                    required
                ></AppInput>
                <AppInput
                    placeholder="Password"
                    label="Password"
                    className="input"
                    type="password"
                    value={password}
                    onChange={setPassword}
                    required
                ></AppInput>
                <AppButton
                    uxType="primary"
                    htmlType="submit"
                    className="button"
                >
                    LOGIN
                </AppButton>
            </form>
        )
    }

    async function handleAuth(e) {
        e.preventDefault()
        try {
            const { user, token } = await api.signIn(email, password)
            dispatch({
                type: "SIGN_IN_FULFILLED",
                payload: {
                    token: token,
                    user: user,
                },
            })
        } catch (e) {
            message.error("Login fallito")
        }
    }

    function handleSignIn(event) {
        event.preventDefault()

        api.signIn(email, password)
            .then((res) => {
                dispatch({
                    type: "SIGN_IN_FULFILLED",
                    payload: res,
                })
            })
            .catch((error) => {
                if (error && error.statusCode === 401) {
                    message.error("Le credenziali non sono corrette.")
                } else {
                    const defaultMessage =
                        "Non è stato possibile completare il login. Riprovare più tardi."

                    const messageText =
                        (error && error.serverMessage) || defaultMessage

                    message.error(messageText)
                }
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <Background className="background">
                <img src={Logo}></img>
                {renderAuthCard()}
            </Background>
            <CommandLine />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AuthPage.propTypes = {
    className: PropTypes.string.isRequired,
}

AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledAuthPage = styled(AuthPage)`
    & {
        height: 100vh;
        .background {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            main {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: -100px;
                img {
                    margin-bottom: 50px;
                }
            }
        }
        .auth-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px 16px;
            background: ${({ theme }) => theme.colors.white};
            border-radius: 16px;
            min-width: 371px;
            .prompt {
                color: ${({ theme }) => theme.colors.grey};
                text-align: left;
                width: 100%;
                font-size: 16px;
                margin-bottom: 18px;
            }
            .card-title {
                text-align: left;
                color: ${({ theme }) => theme.colors.primary};
                width: 100%;
                font-size: 21px;
                margin: 0;
                padding: 0;
                font-family: Jost-SemiBold;
            }
            .input {
                margin-bottom: 15px;
            }
            .button {
                width: 100%;
                margin-top: 10px;
            }
        }
        @media (max-width: 576px) {
            .background {
                .auth-card {
                    max-width: 100%;
                    width: 90%;
                    min-width: 90%;
                }
                main {
                    margin: 0;
                    img {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledAuthPage
